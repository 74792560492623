import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/pages/Home.vue";
import Login from "./components/pages/Login.vue";
import Register from "./components/pages/Register.vue";
// lazy-loaded
const Profile = () => import("./components/pages/Profile.vue")
const BoardAdmin = () => import("./components/pages/BoardAdmin.vue")
const BoardModerator = () => import("./components/pages/BoardModerator.vue")
const BoardUser = () => import("./components/pages/BoardUser.vue")

const TaxRates = () => import("./components/tax-rates/TaxRates.vue")
const Units = () => import("./components/units/Units.vue")
const Countries = () => import("./components/countries/Countries.vue")
const Articles = () => import("./components/articles/Articles.vue")
const Stations = () => import("./components/stations/Stations.vue")
const ExchangeRates = () => import("./components/exchange-rates/ExchangeRates.vue")
const Customers = () => import("./components/customers/Customers.vue")
const Users = () => import("./components/users/Users.vue")
const CustomerTypes = () => import("./components/customer-types/CustomerTypes.vue")
const Cession = () => import("./components/cessions/Cessions.vue")
const CessionForm = () => import("./components/cessions/CessionsForm.vue")
const Selling = () => import("./components/sellings/Sellings.vue")
const SellingForm = () => import("./components/sellings/SellingsForm.vue")
const SellingFormForm = () => import("./components/sellings/FormForm.vue")
const Report = () => import("./components/reports/report.vue")
const Document = () => import("./components/reports/documents/documents.vue")
const Invoice = () => import("./components/invoices/Invoice.vue")
const Export = () => import("./components/invoices/Export.vue")
const InvoiceForm = () => import("./components/invoices/InvoiceForm.vue")
const Reservation = () => import("./components/reservation/Reservation.vue")
const ReservationForm = () => import("./components/reservation/ReservationForm.vue")
const Files = () => import("./components/files/Files.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
  {
    path: "/tax-rates",
    name: "taxRates",
    component: TaxRates,
  },
  {
    path: "/units",
    name: "units",
    component: Units,
  },
  {
    path: "/files",
    name: "filrs",
    component: Files,
  },
  {
    path: "/countries",
    name: "countries",
    component: Countries,
  },
  {
    path: "/articles",
    name: "articles",
    component: Articles,
  },
  {
    path: "/stations",
    name: "stations",
    component: Stations,
  },
  {
    path: "/exchange-rates",
    name: "exchange-rates",
    component: ExchangeRates,
  },
  {
    path: "/customers",
    name: "customers",
    component: Customers,
  },
  {
    path: "/users",
    name: "users",
    component: Users,
  },
  {
    path: "/customer-types",
    name: "customerTypes",
    component: CustomerTypes,
  },
  {
    path: "/cessions",
    name: "cessions",
    component: Cession,
    props: true
  },
  {
    path: "/cessions/:id",
    name: "cession",
    component: CessionForm,
    props: true
  },
  {
    path: "/sellings",
    name: "sellings",
    component: Selling,
  },
  {
    path: "/sellings/:id",
    name: "selling",
    component: SellingForm,
  },
  {
    path: "/sellings/form/:id",
    name: "selling-form",
    component: SellingFormForm,
  },
  {
    path: "/report/:id/:doc/:type",
    name: "report",
    component: Report,
  },
  {
    path: "/report/:id/:doc/:type/:docid",
    name: "report_id",
    component: Report,
    props: true
  },
  {
    path: "/invoices/:type",
    name: "invoices",
    component: Invoice,
  },
  {
    path: "/invoice/:type/:id",
    name: "invoice",
    component: InvoiceForm,
  },
  {
    path: "/invoice/print/:type/:id/:exc",
    name: "invoicePrint",
    component: Document,
  },
  {
    path: "/invoices/export",
    name: "export",
    component: Export,
  },
  {
    path: "/reservation",
    name: "reservations",
    component: Reservation,
  },
  {
    path: "/reservation/:id",
    name: "reservation",
    component: ReservationForm,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;